import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _293da8b0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _412011d0 = () => interopDefault(import('../pages/Icons.vue' /* webpackChunkName: "pages/Icons" */))
const _95ee6282 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _c6705e5e = () => interopDefault(import('../pages/Maps.vue' /* webpackChunkName: "pages/Maps" */))
const _261abe9e = () => interopDefault(import('../pages/Notifications.vue' /* webpackChunkName: "pages/Notifications" */))
const _021ca0d7 = () => interopDefault(import('../pages/table-list.vue' /* webpackChunkName: "pages/table-list" */))
const _f862449e = () => interopDefault(import('../pages/tpgrade.vue' /* webpackChunkName: "pages/tpgrade" */))
const _1535d1e9 = () => interopDefault(import('../pages/Typography.vue' /* webpackChunkName: "pages/Typography" */))
const _9444cc7e = () => interopDefault(import('../pages/user-profile.vue' /* webpackChunkName: "pages/user-profile" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _293da8b0,
    name: "index___en"
  }, {
    path: "/es",
    component: _293da8b0,
    name: "index___es"
  }, {
    path: "/fr",
    component: _293da8b0,
    name: "index___fr"
  }, {
    path: "/Icons",
    component: _412011d0,
    name: "Icons___br"
  }, {
    path: "/login",
    component: _95ee6282,
    name: "login___br"
  }, {
    path: "/Maps",
    component: _c6705e5e,
    name: "Maps___br"
  }, {
    path: "/Notifications",
    component: _261abe9e,
    name: "Notifications___br"
  }, {
    path: "/table-list",
    component: _021ca0d7,
    name: "table-list___br"
  }, {
    path: "/tpgrade",
    component: _f862449e,
    name: "tpgrade___br"
  }, {
    path: "/Typography",
    component: _1535d1e9,
    name: "Typography___br"
  }, {
    path: "/user-profile",
    component: _9444cc7e,
    name: "user-profile___br"
  }, {
    path: "/en/Icons",
    component: _412011d0,
    name: "Icons___en"
  }, {
    path: "/en/login",
    component: _95ee6282,
    name: "login___en"
  }, {
    path: "/en/Maps",
    component: _c6705e5e,
    name: "Maps___en"
  }, {
    path: "/en/Notifications",
    component: _261abe9e,
    name: "Notifications___en"
  }, {
    path: "/en/table-list",
    component: _021ca0d7,
    name: "table-list___en"
  }, {
    path: "/en/tpgrade",
    component: _f862449e,
    name: "tpgrade___en"
  }, {
    path: "/en/Typography",
    component: _1535d1e9,
    name: "Typography___en"
  }, {
    path: "/en/user-profile",
    component: _9444cc7e,
    name: "user-profile___en"
  }, {
    path: "/es/Icons",
    component: _412011d0,
    name: "Icons___es"
  }, {
    path: "/es/login",
    component: _95ee6282,
    name: "login___es"
  }, {
    path: "/es/Maps",
    component: _c6705e5e,
    name: "Maps___es"
  }, {
    path: "/es/Notifications",
    component: _261abe9e,
    name: "Notifications___es"
  }, {
    path: "/es/table-list",
    component: _021ca0d7,
    name: "table-list___es"
  }, {
    path: "/es/tpgrade",
    component: _f862449e,
    name: "tpgrade___es"
  }, {
    path: "/es/Typography",
    component: _1535d1e9,
    name: "Typography___es"
  }, {
    path: "/es/user-profile",
    component: _9444cc7e,
    name: "user-profile___es"
  }, {
    path: "/fr/Icons",
    component: _412011d0,
    name: "Icons___fr"
  }, {
    path: "/fr/login",
    component: _95ee6282,
    name: "login___fr"
  }, {
    path: "/fr/Maps",
    component: _c6705e5e,
    name: "Maps___fr"
  }, {
    path: "/fr/Notifications",
    component: _261abe9e,
    name: "Notifications___fr"
  }, {
    path: "/fr/table-list",
    component: _021ca0d7,
    name: "table-list___fr"
  }, {
    path: "/fr/tpgrade",
    component: _f862449e,
    name: "tpgrade___fr"
  }, {
    path: "/fr/Typography",
    component: _1535d1e9,
    name: "Typography___fr"
  }, {
    path: "/fr/user-profile",
    component: _9444cc7e,
    name: "user-profile___fr"
  }, {
    path: "/",
    component: _293da8b0,
    name: "index___br"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
