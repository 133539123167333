

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"locale":"en","fallbackLocale":"en","messages":{"en":{"welcome":"Welcome!","login":{"username":"User Name","password":"Password","signin":"Sign In","signup":"Sign Up","do_you_agree":"Do you agree?","do_must_agree":"Do must agree to continue!"}},"fr":{"welcome":"Bienvenue!","login":{"username":"Utilisateur","password":"Mot de passe","signin":"S'identifier","signup":"S'inscrire","do_you_agree":"Êtes-vous d'accord?","do_must_agree":"Vous devez accepter de continuer !"}},"es":{"welcome":"Bienvenido!","login":{"username":"Usuario","password":"Contraseña","signin":"Sniciar sesión","signup":"Inscribirse","do_you_agree":"¿Estás de acuerdo?","do_must_agree":"¡Debes estar de acuerdo para continuar!"}},"br":{"welcome":"Bem-vindo!","login":{"username":"Usuário","password":"Senha","signin":"Entrar","signup":"Cadastrar","do_you_agree":"Você aceita?","do_must_agree":"Você precisa aceitar para continuar!"}}}},
  vueI18nLoader: true,
  locales: [{"code":"en","name":"English"},{"code":"fr","name":"Français"},{"code":"es","name":"Spanish"},{"code":"br","name":"Português"}],
  defaultLocale: "br",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: false,
  langDir: null,
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","name":"English"},{"code":"fr","name":"Français"},{"code":"es","name":"Spanish"},{"code":"br","name":"Português"}],
  localeCodes: ["en","fr","es","br"],
}

export const localeMessages = {}
